/*
|--------------------------------------------------------------------------
| Games Details
|--------------------------------------------------------------------------
|
*/

.game-container {
    margin-bottom: 20px;
}


.game-header {
    background: url(/images/game-header-bg.jpg) no-repeat 0 0;
    background-size: 100% 100%;
    padding: 1px;
    color: #fff;
    text-align: center;
}

.game-header .game-date {
    color: #b2b2b2;
    font-weight: bold;
    margin: 10px 0;
    font-size: 14px;
    /*font-size: 15px; whyupper*/
}

.game-header .teams-names {
    display: inline-block;
    text-align: left;
}

.game-header .game-hteam,
.game-header .game-ateam {
    display: block;
    margin: 6px 0;
    font-weight: bold;
    font-size: 15px;
    /*font-size: 16px; whyupper*/
    line-height: 1.1;
} 

.game-header .game-league {
    font-size: 12px;
    /*font-size: 14px; whyupper*/
    margin: 10px 0;
}


.panel-period {
    border-radius: 0;
    border-color: #dfe0e4;
    margin-bottom: 0px;
    border-bottom: 2px solid #bbb;
}

.props-button {
    border-color: #000;
}   

.props-hidden {
    display: none;
}   

.props-iframe {
    width: 100%;
    height: 500px;    
}

#props-spinner {
    height: 40px;
}

.panel-period .panel-heading {
    background-color: #dfe0e4;
    border-color: #dfe0e4;
    padding: 20px;
}

.panel-period .panel-title {
    font-size: 14px;
    color: #3d4558;
    font-weight: bold;
}

.panel-period > .panel-heading .badge-period {
    float: right;
    
    background: none;
    position: relative;
    padding: 0;
    border-radius: 0;
    line-height: 1.42857143;
    font-size: 14px;
    font-weight: bold;
    color: #afafaf;
}

.panel-period .list-group {
    background: #dedede;
}


.panel-period .list-group-item {
    font-weight: bold;
    color: #3d4558;
    border: 1px solid #babdc4;
    padding: 10px 25px;
}

.list-group-item .game-hteam,
.list-group-item .game-ateam {
    max-width: 80%;
    display: inline-block;
}

.list-group-item > .badge-juice {
    background: none;
    padding: 0;
    border-radius: 0;
    color: #0d1424;
    line-height: 1.42857143;
    font-size: 14px;
    font-weight: bold;
}

.panel-period .list-group-item-last {
    margin-bottom: 5px;
}

.game-dteam,
.game-totals .game-hteam,
.game-totals .game-ateam {
    text-transform: uppercase;
}

.game-mkfilter-msgs .alert {
    text-align: center;
    margin-top: 5px;
}


/*
|--------------------------------------------------------------------------
| Games Details Market Filter
|--------------------------------------------------------------------------
|
*/

.game-mkfilter .panel-group {
    margin: 0;
}

.panel-group .panel-mkfilter {
    border-top: 3px solid #0b1422;
    border-radius: 0;
    border-bottom: 2px solid #babdc4;
}

.panel-mkfilter .panel-heading {
    padding: 0;
    border-radius: 0;
}

.panel-mkfilter .panel-heading a {
    padding: 8px 15px;
    display: block;
    outline: none;
    font-size: 15px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

.panel-mkfilter .panel-heading a:hover {
    text-decoration: none;
}

.panel-mkfilter .fa-angle-up {
    font-size: 22px;
    float: right;
    margin-top: -3px;
    margin-right: 5px;
}

.panel-mkfilter .collapsed .fa-angle-up:before {
    content: "\f107";
}

.panel-mkfilter .panel-body {
    padding: 0;
}

.panel-mkfilter .mkfilter-buttons {
    text-align: center;
}

.panel-mkfilter .btn-group {
    width: 100%;
    margin: 1px 0;
}

.panel-mkfilter .btn {
    width: 50%;
    border-radius: 0;
    cursor: pointer;
    border-left-width: 0;
    border-right-width: 0;
}

.panel-mkfilter .btn + .btn {
    margin: 0;
    border-left-width: 1px;
}


.panel-mkfilter .btn.active,
.panel-mkfilter .btn:active {
    color: #fff;
    background: #293243;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .4);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, .4);
}

.panel-mkfilter .btn-group>.btn:last-child {
    border-right: none;
}

.panel-mkfilter .btn-group>.btn:first-child {
    border-left: none;
}

.game-panel-desc {
   text-align: center;
   font-size: 12px;
   line-height: 15px;
   white-space: normal;
} 

.separator-desc {    
    opacity: 0.7;
    border: 1px inset;    
    margin-top: 2px;
    margin-bottom: 10px;    
}


