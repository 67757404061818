/*
|--------------------------------------------------------------------------
| FillSlip Styles
|--------------------------------------------------------------------------
|
*/

.settled-picks {
    font-size: 12px;
    border-bottom: 1px #ccc dashed;
    border-top: 1px #ccc dashed;
    background: #ffffe0;
    border-bottom: 3px solid #0b1422;
}

.settled-picks h3 {
    font-size: 14px;
    text-align: center;
    margin: 10px;
}

.btn-fillslip + .btn-fillslip {
    width: 100%;
    margin-top: 15px;
}

.slip-inputs {
    padding: 10px 10px 15px 10px;
}

/*
|--------------------------------------------------------------------------
| Slip Styles
|--------------------------------------------------------------------------
|
*/

/* Modal slip */

.modal-slip .modal-header {
    background: #293243;
    border: none;
    padding: 12px;
}

.modal-slip .modal-header h4 {
    color: #fff;
    font-weight: bold;
    font-size: 21px;
}

.modal-slip .modal-header .close {
    color: #fff;
    background: none;
    font-size: 30px;
    opacity: 0.6;
    font-weight: normal;
    text-shadow: none;
}

.modal-slip .modal-body {
    padding: 0;
}

.modal-slip .modal-content {
    border: none;
    border-bottom: solid 1px transparent;
    border-radius: 0;
}

#slip-cntr > .alert:only-child {
    margin: 20px 10px;
}

.noscroll {
    position: fixed;
}


/* Bet Type */

.bettype-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 0;
    color: #FFF;
    background: #1C3D6F;
    border: 0;
    letter-spacing: 1px;
    text-align: center;
}

.bettype-panel-body {
    padding: 0;
    color: inherit;
    background-color: transparent;
}

.riskwin-group .col-xs-2 {
    line-height: 30px;
    margin-bottom: 0;
}

.form-group.riskwin-group.row {
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.bettype-row {
    margin-right: -15px;
    margin-left: -15px;
    padding-bottom: 10px;
}

.bettype-body {
    padding: 5px;
    text-align: center;
    border-bottom: #CECFD2 solid 4px;
}
.bettype-body .bettype-body-sizer {
    padding: 5px 15px;
}

.bettype-option {
    margin: 0;
    padding: 0;
}

.bettype-option.radio {
    padding-left: 10px;
    margin-top: 0;
    text-align: left;
}
.bettype-body.block-original {
    padding: 0 12px;
}
.bettype-body.block-original .bettype-option .bettype {
    background-color: #69ACEF;
}

.bettype-body.block-white .bettype {
    background-color: #fff;
    color: #337ab7;
}

.bettype-body.block-white .bettype-option {
    margin: 0;
    text-align: left;
}

.bettype-body.block-white .bettype-option.radio .bettype {

}

.bettype-body.block-blue {

}

.bettype-body.block-blue .bettype {
    background-color: #69acef;
    color: #fff;
    border-radius: 4px;
}

.bettype-body.block-yeallow .bettype-option .bettype {
    background: #ffffe0;
}

.bettype-body.block-grey {
    background: #eee;
}
.bettype-body.block-grey .bettype {
    background-color: #e1e1e1;
    color: #333;
}
.btn-option2 {
    background-color: #69ACEF;
    color: #fff;
}

.bettype-title {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-transform: uppercase;
    color: #0B1629;
    font-size: 14px;
    padding: 8px 20px;
}

#hidden {
    visibility: hidden;
}

#bettype-show {
    display: block;
}



/* Slip */

.slip-wager label
{
    font-weight: normal;
    margin: 0;
}

#slip-cntrls {

}

#slip-cntrls > .alert {
    margin: 10px;
    text-align: center;
}

#slip-cntrls > .alert:only-child {
    margin: 40px 10px;
}

.slip-empty {
    text-align: center;
    margin: 40px 20px;
}

.slip-container .input-sm {
    height: 34px;
    padding: 6px 10px;
    font-size: 16px;
    line-height: 1.5;
}

.form-control {
    font-size: 16px;
}

.stake-control-multibet {
    text-align: right;
}

.slip-wager-header {
    color: #82828C;
    text-transform: uppercase;
    background-color: transparent;
    font-size: 11px;
    letter-spacing: 1px;
    padding: 3px 0;
    margin-bottom: 5px;
    border-bottom: thin dotted #CECFD2;
}

.slip-pick {
    border-bottom: 3px solid #0b1422;
}

.slip-pick-detail {
    text-align: center;
    background: #fff;
    color: #000;
    font-weight: bold;
    padding: 5px;
    margin: 10px auto;
    -moz-box-shadow: 0.05em 0.05em 0.05em #B9C0D0;
    box-shadow: 0.05em 0.05em 0.05em #B9C0D0;
    border: 1px solid #b9c0d0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.slip-pick-detail p {
    margin: 0 0 2px 0;
}

.slip .rrcomb-control {
    border: 1px solid #b9c0d0;
    padding: 7px 5px 5px;
    text-align: left;
}

.bet-options {
    padding: 0 0 20px 0;
    overflow: hidden;
}

.bet-options input {
    margin: 0 2px 0 0;
    vertical-align: middle;
}

.bet-options span {
    vertical-align: middle;
}

.bet-options label {
    margin-bottom: 15px;
    font-weight: normal;
    display: block;
}

.bet-options label:last-child {
    margin-bottom: 0;
}

.slip .btn-fillslip {
    width: 100%;
    margin: 15px 0 10px 0;
}

.slip .btn-fillslip + .btn-fillslip {
    margin-top: 0;
}

.stake-control-multibet input {
    text-align: center;
    border-radius: 0;
}

#clear-button {
    border-radius: 0;
    font-weight: bold;
    padding: 6px 12px;
    font-size: 16px;
}

#change-button {
    border-radius: 0;
    font-weight: bold;
    padding: 6px 12px;
    font-size: 16px;
}
#slip-button {
    border-radius: 0;
}

#block-button {

}

#block-button .btn-primary {
    color: #fff;
    background-color: #2fa73b;
    padding: 13px 14px;
    font-size: 21px;
    font-weight: bold;
    border-radius: 0;
    width: 100%;
    border: none;
}

.btn-grey {
    padding: 10px 14px;
    color: #0b1422;
    font-size: 15px;
    background-color: #e1e1e1;
    border-radius: 0;
}

.text-left {
    text-align: left;
}

.btn-primary {
    background-color: #33577b;
    color: #fff;
}

.btn-danger {
    border-radius: 0;
}

.word-wrap {
    word-wrap: break-word;
}

.panel-pick {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    border: none;
}

.panel-pick > .panel-heading {
    background: #e1e1e1;
    border: none;
    border-radius: 0;
    overflow: hidden;
    padding: 10px;
}

.panel-pick > .panel-heading h3 {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    color: #0b1422;
    font-weight: bold;
    vertical-align: middle;
    max-width: 90%;
}

.panel-pick > .panel-body {
    padding: 10px;
}

.panel-pick .pitchers-row label {
    margin: 1px 0 5px 10px;
}

.panel-pick > .panel-body p {
    margin-bottom: 0px;
}

.panel-pick > .panel-body .pick-price {
    padding: 10px 5px;
    font-weight: bold;
    background-color: #293243;
    color: #fff;
    text-align: center;
    margin: 0;
    line-height: 1;
}

.rrcomb-control {

}

.rrcomb-control label {
    display: block;
    font-weight: normal;
    margin: 0;
}

.slip-inputs {
    padding: 10px 10px 15px 10px;
}

.stake-control {
    line-height: 34px;
    text-align: right;
}

.stake-control select,
.stake-control input {
    height: 34px;
    font-size: 16px;
    font-weight: normal;
    border-radius: 0;
    display: inline-block;
}

.stake-control label {
    font-weight: normal;
}

.stake-controls {
    line-height: 40px;
}
.stake-controls .stake-control {
    margin-top: 15px;
}

.stake-line-block {
    overflow: hidden;
    position: relative;
}

.stake-line-block .col-xs-6 {
    padding-left: 10px;
    padding-right: 10px;
}

.stake-line-block .stake-control {
    text-align: left;
    margin-bottom: 0;
    padding-bottom: 5px;
}
.stake-line-block .stake-control label,
.stake-line-block .stake-control input,
.stake-line-block .stake-control select {
    display: block;
    float: none;
    width: 100%;
}

.stake-line-block .stake-control label {
    line-height: 25px;
}

.stake-line-block .stake-control select {
    font-weight: normal;
}

.riskwin-control {
    text-align: right;
    margin: 0;
    padding: 10px 0 10px 0;
}

.riskwin-control .riskwin-ctrl {
    margin: 4px 0 0 0;
}

.riskwin-control .radio-inline {
    padding-top: 0;
}

.riskwin-control .padding-l-0 {
    padding-top: 0;
}

.riskwin-control .radio-inline + .radio-inline {
    margin-left: 15px;
    margin-right: 2px;
}

.riskwin-control input {
    vertical-align: middle;
    margin-top: 0;
}

.riskwin-control label {
    font-weight: normal;
}

.riskwin-control span {
    vertical-align: middle;
}

/* ========================================================================
 * Slip Summary
 * ======================================================================== */

.slip-summary {
    background: #f1f1f1;
}

.slip-summary table {
    margin: 0;
}

.slip-summary th {
    padding-left: 12px;
}

.slip-summary td {
    text-align: right;
    padding-right: 12px;
}

.text-danger {
    text-align: center;
    padding: 5px;
    margin-bottom: 0;
}


/* ========================================================================
 * Slip Modal
 * ======================================================================== */

.modal-content {
    box-shadow: 2px 2px 2px #1C3D6F;
}

.panel-multibet {
    margin-top: 10px;
    margin-bottom: 10px;
}

.panel-multibet .panel-heading {
    padding: 0;
    border-radius: 0;
}

.panel-multibet .panel-heading a,
.panel-multibet .panel-heading .rrtitle {
    padding: 13px 15px;
    display: block;
    color: #0b1422;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
}

.panel-multibet .panel-heading .rrtitle p {
    margin: 0;
}

.panel-multibet .fa-minus {
    float: right;
    margin-right: 5px;
    color: #aaa;
    font-size: 14px;
    line-height: 16px;

}

.panel-multibet .collapsed .fa-minus:before {
    content: "\f067";
}

.rrcomb-control {
    margin: 0;
}

.rrcomb-control label {
    display: block;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}


/*Slip opener*/
.slip-opener {
    background-color: #30a73b;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    position: fixed;
    z-index: 100;
    bottom: 10px;
    right: 10px;
    -webkit-box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
}

.padding-b-10 {
    padding-bottom: 10px;
}

.padding-b-5 {
    padding-bottom: 5px;
}

.padding-b-0 {
    padding-bottom: 0;
}

.padding-l-10 {
    padding-left: 10px;
}

.padding-l-5 {
    padding-left: 5px;
}

.padding-l-0 {
    left: 0;
}

.padding-r-10 {
    padding-right: 10px;
}

.padding-r-5 {
    padding-right: 5px;
}

.padding-r-0 {
    padding-right: 0;
}

.padding-t-5 {
    padding-top: 5px;
}

.padding-t-10 {
    padding-top: 10px;
}

.padding-10 {
    padding: 10px;
}

.padding-5 {
    padding: 5px;
}

.padding-0 {
    padding: 0;
}
.margin-0 {
    margin: 0;
}

/* Extra Small Devices make the modal slip have the entire width */
@media only screen and (max-width : 480px) {

    .modal-slip .modal-dialog {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-slip .modal-content {
        border-radius: 0;
    }

}
