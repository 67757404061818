@import 'font-sports';
@import 'animate';
@import 'sidenav-ctrl';
@import 'sidenav-theme';
@import 'spinner-theme';
@import 'game-ctrl';
@import 'game-theme';
@import 'games-theme';
@import 'breadcrumb-theme';
@import 'slip-theme';
/*@import 'fillslip-theme';*/
@import 'gototop-ctrl';
@import 'gototop-theme';
@import 'toast-ctrl';
@import 'toast-theme';
@import 'balance';
@import 'pending';
@import 'settings';
@import 'history';
@import 'textpage-theme';
@import 'open-wagers';
@import 'template';
@import 'sbplays';
@import 'loading';
