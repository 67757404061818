/* ========================================================================
 * history
 * ======================================================================== */

.history-panel h2 {
  background: #338cba;
  border-top: none;
  padding: 6px 6px;
  font-size: 13px;
}


/* ========================================================================
 * breadcrumb-theme
 * ======================================================================== */

.page-bar .breadcrumb {
  background: #338cba;
}

.page-bar .breadcrumb > li::after {
  border-left: 10px solid #338cba;
}

.page-bar .breadcrumb > li::before {
  border-left: 10px solid #00519e;
}


/* ========================================================================
 * games-theme
 * ======================================================================== */

.panel-tsfilter .panel-heading {
  padding: 8px 15px;
}

.panel-tsfilter .panel-title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

/* ========================================================================
 * sidenav-theme
 * ======================================================================== */

.sidenav-close {
  background: #003162;
  text-align: right;
  border-bottom: none;
}

.sidenav-menu {
  background: #003162;
}

.sidenav-menu ul ul {
  background: #338cba;
}

.sidenav-menu nav > ul > li > a.nav-parent-selected,
.sidenav-menu nav > ul > li > a.collapsed.nav-parent-selected {
  background: #e5612b;
}

.sidenav-menu nav ul ul > li > a {
  background: #204972;
}

.sidenav-menu nav ul ul > li > a.nav-item-selected {
  background: #2c587c;
}

#nav-side-cntr {
  background: #003162;
}

.nav-side {
  background: #003162;
}

.sidenav-menu nav > ul > li > a {
  padding: 10px 15px;
  border-top: 1px solid #338cba;
}

/* ========================================================================
 * timer-theme
 * ======================================================================== */

.header {
  background: #003162;
}

/* ========================================================================
 * template
 * ======================================================================== */
body.app {
  background: #003162;
}

.panel-login {
  margin-top: 20%;
  border-radius: 10px;
  background: #ccc;
  color: #000033;
}

.panel-login .panel-body {
  background: url('/images/login-panel-bg.gif') center 35px repeat-x;
}

.panel-login form {
  background: url('/images/logos/sbplays-logo-login.png') center 0 no-repeat;
  padding-top: 110px;
}

.panel-login .btn-primary {
  background: #338cba;
  float: right;
  padding: 6px 25px;
}

.panel-login .btn-primary:hover {
  background: #36abe7;
  transition: all 500ms ease;
}

#labelmsg {
  padding: 5px;
}

#subcaptcha {
  height: 100%;
}

.captcha-container {
  display: grid;
  place-items: center;
  height: 100%;
}

.captcha-content {
  height: 100%;
  width: 100%;
  //height: 28vh;
}

.captcha-container iframe {
  width: 100%;
  height: 180px;
}


/*@media (min-width: 768px) {
  .captcha-container {
      width: 50%;
      margin: 0 auto;
  }
}*/

/*@media (max-device-width: 250px) {
  .captcha-container iframe {
    width: 100%;
    height: 29vh;
  }
}*/

@media (min-device-width: 500px) {
  /*.captcha-container iframe {
    width: 100%;
    height: 28vh;
  }*/
  #postWagerModalBody{
    height: 20vh;
  }
}

@media (max-device-width: 376px) {
  /*.captcha-container iframe {
    width: 100%;
    height: 28vh;
  }*/
  #postWagerModalBody{
    height: 22vh;
  }
}


@media (orientation: landscape)
{
  #postWagerModalBody{
    height: 45vh;
  }
  /*.captcha-container iframe {
    width: 100%;
    height: 70vh;
  }*/
}